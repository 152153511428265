import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import { useNavigate } from "react-router-dom";
import {
  filterCollaborations,
  getMyCollaborationsCall,
} from "../../Common/Repository";
import { FullSearchIcon, ListIcon } from "../../Components/Icons";
import CustomizedSlider from "../../Components/CustomizedSlider";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { setCollaborations } from "../../states/collaborations/collaborationSlice";
import { PuffLoader } from "react-spinners";

const Collabration = () => {
  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const { collaborationsData, loading } = useSelector(
    (state) => state.collaborations
  );

  const [search, setSearch] = useState("");

  const [minPrice, setMinPrice] = useState(1);

  const [maxPrice, setMaxPrice] = useState(100000);

  const [priceFilterResetToggle, setPriceFilterResetToggle] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");

  const [resetTrue, setResetTrue] = useState(false);

  const [firstTime, setFirstTime] = useState(true);

  const [filterApplied, setFilterApplied] = useState(false);

  const navigate = useNavigate();

  const handleDateSelect = (event) => {
    setSelectedDate(event?.target?.value);
    let DateEvent = event?.target?.value;
    if (DateEvent === "") {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
    setResetTrue(true);
  };

  const getMyCollaborationsFromServer = async () => {
    try {
      let data = {};
      dispatch(getMyCollaborationsCall({ token, data }));
    } catch (error) { }
  };


  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      getMyCollaborationsFromServer();
    } else {
      if (selectedDate || minPrice || maxPrice || resetTrue) {
        filterMyCollaborations();
      } else {
        getMyCollaborationsFromServer();
      }
    }
  }, [selectedDate, minPrice]);

  const filterMyCollaborations = async () => {
    try {
      var formData = {};

      if (search.length > 0) {
        formData.name = search;
      }

      // Check if there is a change in the slider values
      const sliderValueChanged = minPrice !== 1 || maxPrice !== 100000;

      // Only include price in form data if there is a change in slider values
      if (sliderValueChanged) {
        formData.price_min = minPrice;
        formData.price_max = maxPrice;
      }

      if (selectedDate !== "") {
        formData.created_at = selectedDate;
      }

      let response = await filterCollaborations(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      if (response?.status === 200) {
        dropdownOpen();
      }
      dispatch(setCollaborations(response?.data?.data));
    } catch (error) { }
  };

  const sliderValues = (event, newValue, customKey) => {
    if (customKey === "price") {
      setMinPrice(newValue[0]);
      setMaxPrice(newValue[1]);
    }
  };

  const handleCollaborationClick = (collaborationId) => {
    navigate("/collabrationDetails", {
      state: {
        collaborationId,
      },
    });
  };

  const formattedValue = (value, customKey) => {
    if (customKey === "audience" || customKey === "followers") {
      if (value < 1000) {
        return `${value}`;
      } else if (value < 1000000) {
        return `${(value / 1000).toFixed(0)}K`;
      } else {
        return `${(value / 1000000).toFixed(1)}M`;
      }
    } else if (customKey === "price") {
      return `$${value < 1000 ? value.toFixed(0) : (value / 1000).toFixed(0)}K`;
    } else if (customKey === "engagement") {
      return `${value.toFixed(1)}%`;
    } else {
      return value;
    }
  };

  const formatDate = (inputDate) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const date = new Date(inputDate);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000); // Convert to UTC
    return utcDate.toLocaleDateString("en-US", options);
  };

  const requestListData = {
    listTitle: [
      user?.role === "2" ? "Profile" : "Collaboration",
      user?.role === "1" ? "Profile" : "",
      "Proposal Date",
      user?.role === "2" ? "Followers" : "",
      "Content",
      "Price",
      "Action History",
    ],
  };

  const dropdownOpen = (event) => {
    if (event) {
      const obj = event.currentTarget;
      if (window.$(obj).find(".innerDropDown").hasClass("open")) {
        window.$(obj).find(".innerDropDown").removeClass("open");
      } else {
        window.$(".innerDropDown").removeClass("open");
        window.$(obj).find(".innerDropDown").addClass("open");
        window.$(obj).find(".innerDropDown").removeClass("d-none");
      }
    } else {
      window.$(".innerDropDown").removeClass("open");
    }
  };

  const dropdownBox = (event) => {
    event.stopPropagation();
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#549A9C",
      color: "white",
      fontSize: 18,
      minWidth: 170,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      minWidth: 170,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#EAF9FA",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "white",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <AppLayout>
      <div className="categories-dashboard p-5 ">
        <div className="container container-animation">
          <img
            className="purple-circle"
            src="/assets/images/circle_top1.png"
            alt="circle_top1"
          />
          <img
            className="yellow-circle"
            src="/assets/images/circle-03.png"
            alt="circle_top1"
          />
          <h4 className="mx-2">Collaborations</h4>
          <div className="collaborationsCatalogue">
            <div className="searchBar">
              <div className="d-flex align-items-center position-relative search-bar--input-container -tags width-50 wm-100">
                <div
                  className="search-tag-position"
                  onClick={() => {
                    if (search.length) {
                      filterMyCollaborations();
                    }
                  }}
                >
                  <FullSearchIcon
                    color={!search.length ? "#93BFC0" : null}
                    width="133px"
                  />
                </div>
                <span className="d-flex align-items-center w-100">
                  <div
                    className="ant-select ant-select-auto-complete search-bar--input css-10evijz ant-select-single ant-select-show-search w-100"
                    autoComplete="off"
                  >
                    <div className="ant-select-selector w-100">
                      <span className="ant-select-selection-search w-100">
                        <input
                          type="text"
                          className="ant-select-selection-search-input w-100"
                          id="search"
                          value={search}
                          placeholder="Search by Profile"
                          role="combobox"
                          aria-haspopup="listbox"
                          aria-owns="rc_select_8_list"
                          aria-autocomplete="list"
                          aria-controls="rc_select_8_list"
                          aria-activedescendant="rc_select_8_list_-1"
                          aria-expanded="false"
                          onChange={(event) => setSearch(event.target.value)}
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              if (search !== "") {
                                filterMyCollaborations();
                              }
                            } else if (event.key === "Backspace") {
                              if (search === "") {
                                event.preventDefault();
                                if (filterApplied) {
                                  filterMyCollaborations();
                                } else {
                                  getMyCollaborationsFromServer();
                                }
                              }
                            } else if (event.ctrlKey && event.key === "x") {
                              if (
                                event.target.selectionStart === 0 &&
                                event.target.selectionEnd === search.length
                              ) {
                                // If text is selected and then deleted (Ctrl + X)
                                event.preventDefault();
                                setSearch("");
                                getMyCollaborationsFromServer();
                              }
                            }
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </span>
              </div>
              {/* new section  */}
              <div className="innerSection">
                <div
                  className="campaigns-date-dropdown-box dropdown-box mb-2 mb-md-0"
                  onClick={dropdownOpen}
                >
                  <div className="dropDownText">
                    {/* <p className="m-0">
                      {selectedDate !== "" ? selectedDate : "Creation Date"}
                    </p> */}
                    <div className="date-picker-button d-flex position-relative">
                      <input
                        className="border-0 mt-0 no-outline smallText"
                        type="date"
                        id="date_calendar"
                        max={new Date().toISOString().split("T")[0]}
                        value={selectedDate}
                        onChange={(event) => {
                          handleDateSelect(event);
                        }}
                      />
                      {/* <img
                        onClick={() => {
                          document
                            .getElementById("date_calendar")
                            .showPicker();
                        }}
                        className="calender-icon"
                        src="/assets/images/calenderIcon.svg"
                        alt="date"
                        width={25}
                      /> */}
                    </div>
                  </div>
                </div>

                <div
                  className="dropDown wm-100 mb-2 mb-md-0"
                  onClick={dropdownOpen}
                >
                  <div className="dropDownText wm-100">
                    <p className="m-0 smallText">
                      Price
                      {`: ${formattedValue(minPrice, "price")}-${formattedValue(
                        maxPrice,
                        "price"
                      )}`}
                    </p>
                    <img
                      src="/assets/images/arrow-sign.png"
                      alt=""
                      width={15}
                      className=""
                    />
                  </div>

                  <div className="innerDropDown mx-auto" onClick={dropdownBox}>
                    <div className="slider-box mt-3">
                      <CustomizedSlider
                        customKey={"price"}
                        minValue={minPrice}
                        maxValue={maxPrice}
                        handleChange={(event, newValue) =>
                          sliderValues(event, newValue, "price")
                        }
                        rangeClassName="rangeValue"
                        SliderWidth={180}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div
                        onClick={() => {
                          setPriceFilterResetToggle(!priceFilterResetToggle);
                          setMinPrice(1);
                          setMaxPrice(100000);
                        }}
                        className="text-dark fs-6 link-style m-0"
                      >
                        Reset
                      </div>
                      <div
                        onClick={filterMyCollaborations}
                        className="greenButton apply-btn"
                      >
                        Apply
                      </div>
                    </div>
                  </div>
                </div>
                {/* Reset All Filters */}
                <div
                  onClick={() => {
                    setSearch("");
                    setMinPrice(1);
                    setMaxPrice(100000);
                    setSelectedDate("");
                    getMyCollaborationsFromServer();
                  }}
                  className="reset-all-filter-icon d-flex align-items-center "
                >
                  {/* Reset */}
                  <img src="/assets/images/reset-icon.svg" alt="reset" />
                </div>
              </div>

              <div className="iconDiv wm-100 hide-on-phone">
                <ListIcon
                  className="selectedCatalogueIcon"
                  strokeColor="white"
                />
              </div>

              {/* new section  */}
            </div>
          </div>

          {loading ? (
            <>
              <div className="p-5 m-5 d-flex justify-content-center">
                <PuffLoader color="#65acae" size={42} />
              </div>
            </>
          ) : (
            <>
              {collaborationsData && collaborationsData.length > 0 ? (
                <div
                  className="collaborationCatalogueTable"
                  id="listing-view-catalogues"
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {requestListData.listTitle.map((cTitle) => {
                            return (
                              <React.Fragment key={cTitle}>
                                {cTitle === "" ? (
                                  ""
                                ) : (
                                  <StyledTableCell
                                    align={
                                      cTitle === "Content"
                                        ? "left"
                                        : cTitle === "Profile"
                                          ? "left"
                                          : cTitle === "Action History"
                                            ? "right"
                                            : "center"
                                    }
                                  >
                                    {cTitle}
                                  </StyledTableCell>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {collaborationsData.map((collabData) => (
                          <StyledTableRow
                            key={collabData.id}
                            onClick={() => {
                              handleCollaborationClick(collabData.id);
                            }}
                            hover={true}
                          >
                            {user?.role === "2" ? (
                              <StyledTableCell component="th" scope="row">
                                {collabData.creator?.profile_name || "-"}
                              </StyledTableCell>
                            ) : (
                              <>
                                <StyledTableCell align="center" scope="row">
                                  {collabData.id
                                    ? `Collaboration#${collabData.id}`
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {collabData.brand?.brand_name || "-"}
                                </StyledTableCell>
                              </>
                            )}
                            <StyledTableCell align="center" scope="row">
                              {formatDate(collabData?.created_at)}
                            </StyledTableCell>
                            {user?.role === "2" ? (
                              <StyledTableCell align="center" scope="row">
                                {collabData?.creators?.followers_count || "-"}
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}

                            <StyledTableCell align="left">
                              <div
                                style={{
                                  wordBreak: "break-all",
                                  overflow: "hidden",
                                  lineHeight: "normal",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {collabData.offers[0]?.description || "-"}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {"$" + (collabData.offers[0]?.price || "-")}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <button
                                className={`d-flex px-3 table-status-btn btn ${collabData.status === "completed" ||
                                  collabData.status === "approved"
                                  ? "status-complete"
                                  : collabData.status === "rejected" ||
                                    collabData.status === "appealed"
                                    ? "status-rejected"
                                    : collabData.status === "pending" ||
                                      collabData.status === "ongoing"
                                      ? "status-ongoing"
                                      : ""
                                  }`}
                              >
                                <span className="me-2">
                                  <div
                                    className={
                                      collabData.status === "completed" ||
                                        collabData.status === "approved"
                                        ? "dot-complete"
                                        : collabData.status === "rejected" ||
                                          collabData.status === "appealed"
                                          ? "dot-rejected"
                                          : collabData.status === "pending" ||
                                            collabData.status === "ongoing"
                                            ? "dot-ongoing"
                                            : ""
                                    }
                                  ></div>
                                </span>
                                <div className="w-100 text-start">
                                  {collabData.status.charAt(0).toUpperCase() +
                                    collabData.status.slice(1)}
                                </div>
                              </button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="nothing-found">No Collaboration Found!</div>
              )}
            </>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Collabration;
