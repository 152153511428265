import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useState, useEffect, useRef } from 'react';


const RangeSlider = styled(Slider)({
    color: "#549A9C",
    height: 14,
    '& .MuiSlider-rail': {
        border: '1px solid rgba(85, 155, 157, 0.90)',
        background: '#EEFCFD'
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 32,
        width: 32,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});


function CustomizedSlider({ customKey, minValue, maxValue, handleChange, SliderWidth, rangeClassName, setMaxValue, setMinValue }) {
    const [isMinDoubleClicked, setIsMinDoubleClicked] = useState(false);
    const [isMaxDoubleClicked, setIsMaxDoubleClicked] = useState(false);

    const minInputRef = useRef(null);
    const maxInputRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (minInputRef.current && !minInputRef.current.contains(event.target)) {
                setIsMinDoubleClicked(false);
            }
            if (maxInputRef.current && !maxInputRef.current.contains(event.target)) {
                setIsMaxDoubleClicked(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [minInputRef, maxInputRef]);

    const formattedValue = (value) => {
        if (customKey === "audience" || customKey === "followers" || customKey === "price") {
            if (value < 1000) {
                return `${value}`;
            } else if (value < 1000000) {
                return `${(value / 1000).toFixed(0)}K`;
            } else {
                return `${(value / 1000000).toFixed(1)}M`;
            }
        } else if (customKey === "engagement") {
            return `${value}%`
        }
        else {
            return value;
        }
    };

    return (
        <Box
            sx={{
                width: SliderWidth ?? 320
            }}>
            <Box sx={{ m: 3 }} />
            <RangeSlider
                value={[minValue, maxValue]}
                onChange={handleChange}
                defaultValue={[minValue ?? 0, maxValue ?? 100]}
                min={customKey === "audience" ? 1000 : customKey === "followers" ? 1000 : customKey === "engagement" ? 0 : customKey === "age" ? 18 : customKey === "price" ? 0 : undefined}
                max={customKey === "audience" ? 10000000 : customKey === "followers" ? 1000000 : customKey === "engagement" ? 50 : customKey === "age" ? 100 : customKey === "price" ? 100000 : undefined}
            />
            <div
                className={rangeClassName ?? "range_value"}>
                <span
                    onDoubleClick={() => setIsMinDoubleClicked(true)} className="range-value min">
                    {isMinDoubleClicked && (customKey === "followers" || customKey === "audience") ?
                        <input
                            ref={minInputRef}
                            style={{ width: '50px', fontSize: '12px', paddingTop: '5px', paddingBottom: '5px' }}
                            value={minValue}
                            onChange={(e) => {
                                setMinValue(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setIsMinDoubleClicked(false);
                                }
                            }}
                        />
                        :
                        <>
                            {formattedValue(minValue)}
                        </>
                    }
                </span>

                <span onDoubleClick={() => setIsMaxDoubleClicked(true)} className="range-value max">
                    {isMaxDoubleClicked && (customKey === "followers" || customKey === "audience") ?
                        <input
                            ref={maxInputRef}
                            style={{ width: '50px', fontSize: '12px', paddingTop: '5px', paddingBottom: '5px' }}
                            value={maxValue}
                            onChange={(e) => {
                                setMaxValue(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setIsMaxDoubleClicked(false);
                                }
                            }}
                        /> : <> {formattedValue(maxValue)} </>}
                </span>
            </div>

        </Box>
    );
}

export default CustomizedSlider;
