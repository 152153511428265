// var FileSaver = require("file-saver");
// import {saveAs} from "file-saver";

import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";

const downloadFile = (filePath, fileName, fileType) => {
  fetch(filePath, {
    // fetch("https://cors-anywhere.herokuapp.com/" + filePath, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/octet-stream",
    // },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.download = fileName + "." + fileType;

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    });
};

const WorkAcceptModal = ({ userImage, time }) => {
  return (
    <>
      <div className="d-flex align-items-start work-accepted-msg recive-msg mt-2">
        <img className="profile-image-sm" src={userImage} alt="Avtar1" />
        <div className="text-rec-mdg">
          <p>
            <span>Work Accepted...</span>
            <br />
            <span className="fw-normal">
              Please upload the content on your influencer page and submit the
              link to the content.
            </span>
          </p>
          <div className="txt-time">{time}</div>
        </div>
      </div>
    </>
  );
};

const OtherUserMessage = ({
  message,
  time,
  userImage,
  file,
  fileName,
  type,
  user,
}) => {
  return message &&
    !message.includes("Decision made in Brand favor") &&
    !(
      message.includes("Link proof accepted") && user?.role.toString() === "2"
    ) &&
    !message.includes("Decision made in Creator favor") &&
    !message.includes("Report decision made in favor of creator") &&
    !message.includes("Report decision made in favor of brand") &&
    !message.includes("Payment Processed, Collaboration Completed") ? (
    <div className="d-flex align-items-start recive-msg">
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        {message.includes("You have received an invite for campaign") ||
        message.includes("Chat Request Received") ||
        message.includes("Chat Request Sent") ||
        message.includes("An invite request to creator") ||
        message.includes("Link Submitted") ||
        message.includes("Link Submitted Again") ||
        // message.includes("Work Change Request Sent") ||
        message.includes("Work Submission Request sent") ||
        message.includes("Work Submission Request Sent Again") ||
        message.includes("Customized offer created") ||
        message.includes("You have received an invite for campaign from") ||
        message.includes("An invite request to creator") ? (
          <p className="blueLinkColor fontBold">{message}</p>
        ) : message.includes("Collaboration Accepted By") ||
          message.includes("Offer Accepted") ||
          message.includes("has bough the pack") ||
          // message.includes("Extension Request Sent") ||
          // message.includes("Link Proof Accepted") ||
          // message.includes("Extension Requested Received") ||
          message.includes("Work Submission Accepted") ||
          message.includes("Extension Requested Accepted") ||
          message.includes("Extension request accepted") ? (
          <p className="primaryColor fontBold">{message}</p>
        ) : message.includes("Offer rejected") ||
          message.includes("Issue Reported") ||
          message.includes("Proof Rejected") ||
          message.includes("Proof Rejected Again") ||
          message.includes("Extension Request Rejected") ||
          message.includes("Extension request Rejected") ? (
          <p className="redColor fontBold">{message}</p>
        ) : message.includes("Appeal Request") ? (
          <p className="yellowColor fontBold">{message}</p>
        ) : (
          <p>
            {message}
            <br />
            {file !== null && file !== undefined ? (
              <span
                className="greenColor cursor-pointer"
                onClick={() => {
                  downloadFile(
                    file,
                    `${fileName.split(".")[0]} - ${Date.now()}`,
                    type
                  );
                }}
              >
                {`File Received - Tap to Download - ${fileName}`}
              </span>
            ) : (
              <></>
            )}
          </p>
        )}
        <div className="txt-time">{time}</div>
      </div>
    </div>
  ) : file !== null && file !== undefined ? (
    <div className="d-flex align-items-start recive-msg">
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <p className="cursor-pointer"
          onClick={() => {
            downloadFile(
              file,
              `${fileName.split(".")[0]} - ${Date.now()}`,
              type
            );
          }}
        >
          {`File Received - Tap to Download - ${fileName}`}
        </p>
        <div className="txt-time">{time}</div>
      </div>
    </div>
  ) : null;
};

const SentMessage = ({
  message,
  time,
  userImage,
  file,
  fileName,
  type,
  user,
}) => {
  return message &&
    !message.includes("Decision made in Brand favor") &&
    !(
      message.includes("Link proof accepted") && user?.role.toString() === "1"
    ) &&
    !message.includes("Decision made in Creator favor") &&
    !message.includes("Payment Processed, Collaboration Completed") ? (
    <div className="d-flex align-items-start recive-msg rply-rec-msg">
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        {message.includes("You have received an invite for campaign") ||
        message.includes("An invite request to creator") ||
        message.includes("Link Submitted") ||
        message.includes("Chat Request Received") ||
        message.includes("Chat Request Sent") ||
        message.includes("Link Submitted Again") ||
        message.includes("Work Change Request Sent") ||
        message.includes("Work Submission Request sent") ||
        message.includes("Work Submission Request Sent Again") ||
        message.includes("Customized offer created") ||
        message.includes("You have received an invite for campaign from") ||
        message.includes("An invite request to creator") ? (
          <p className="blueLinkColor fontBold">{message}</p>
        ) : message.includes("Collaboration Accepted By") ||
          message.includes("Offer Accepted") ||
          message.includes("has bough the pack") ||
          // message.includes("Extension Request Sent") ||
          // message.includes("Link Proof Accepted") ||
          // message.includes("Extension request received") ||
          message.includes("Work Submission Accepted") ||
          message.includes("Extension Requested Accepted") ||
          message.includes("Extension request accepted") ? (
          <p className="primaryColor fontBold">{message}</p>
        ) : message.includes("Offer rejected") ||
          message.includes("Issue Reported") ||
          message.includes("Proof Rejected") ||
          message.includes("Proof Rejected Again") ||
          message.includes("Extension request Rejected") ||
          message.includes("Extension Request Rejected") ||
          message.includes("Extension request Rejected") ? (
          <p className="redColor fontBold">{message}</p>
        ) : message.includes("Appeal Request") ? (
          <p className="yellowColor fontBold">{message}</p>
        ) : (
          <p>
            {message}
            <br />
            {file !== null && file !== undefined ? (
              <span
                className="greenColor cursor-pointer"
                onClick={() => {
                  downloadFile(
                    file,
                    `${fileName.split(".")[0]} - ${Date.now()}`,
                    type
                  );
                }}
              >
                {`File Sent - Tap to Download - ${fileName}`}
              </span>
            ) : (
              <></>
            )}
          </p>
        )}
        <div className="txt-time">{time}</div>
      </div>
    </div>
  ) : file !== null && file !== undefined ? (
    <div className="d-flex align-items-start recive-msg rply-rec-msg">
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <p
          className="cursor-pointer"
          onClick={() => {
            downloadFile(
              file,
              `${fileName.split(".")[0]} - ${Date.now()}`,
              type
            );
          }}
        >
          {`File Sent - Tap to Download - ${fileName}`}
        </p>
        <div className="txt-time">{time}</div>
      </div>
    </div>
  ) : null;
};

const ReviewGiven = ({ isSender, comment, rating, time, userImage }) => {
  return (
    <>
      <div
        className={`d-flex align-items-start recive-msg mt-2 ${
          isSender ? "review-sent-msg" : "review-received-msg"
        }`}
      >
        <img className="profile-image-sm" src={userImage} alt="Avtar1" />
        <div className="text-rec-mdg">
          <p>
            <div className="">
              {isSender ? "Left" : "Received"} a {rating} Stars Review...
            </div>
            <div className="text-muted fw-light"> {comment} </div>
          </p>
          <div className="txt-time mb-2">{time}</div>
        </div>
      </div>
    </>
  );
};

const InfluencerLinkModal = ({
  contentLink,
  workLoading,
  setContentLink,
  selectAttachment,
  submitLink,
  errors,
  fieldPreview,
  setFilesToBeSent,
  setFieldPreview,
}) => {
  const [errorShow, setErrorShow] = useState("");

  useEffect(() => {
    setErrorShow(errors);
  }, [errors]);

  return (
    <div
      className="modal fade"
      id="influencer-link-modal"
      tabIndex={-1}
      aria-labelledby="influencer-link-modal"
      aria-hidden="true"
    >
      <div className="modal-dialog user-settings-modal ">
        <div className="modal-content">
          <div className="modal-body pt-5">
            <img
              className="blueBallLeft"
              src="/assets/images/blue_ball.png"
              alt="blue-ball"
              width={25}
            />
            <img
              className="yellowCircleRight"
              src="assets/images/yellow_circle.png"
              alt="circle_3"
              width={16}
            />
            <img
              className="purpleEmptyCircleRight"
              src="assets/images/purple_empty_circle.png"
              alt="empty_circle"
              width={20}
            />
            <img
              className="purplePlusRight"
              src="assets/images/purple_plus.png"
              alt="plus"
              width={16}
            />
            <img
              className="purpleEmptyLeft"
              src="assets/images/purple_empty_circle.png"
              alt="empty_circle_2"
              width={16}
            />
            <div className="my-3">
              <input
                type="search"
                className="inputGenericOne linkContent"
                id="search"
                placeholder="Link for the posted content..."
                role="combobox"
                aria-haspopup="listbox"
                aria-owns="rc_select_8_list"
                aria-autocomplete="list"
                aria-controls="rc_select_8_list"
                aria-activedescendant="rc_select_8_list_-1"
                aria-expanded="false"
                value={contentLink}
                onChange={(event) => {
                  setContentLink(event.target.value);
                  setErrorShow("");
                }}
              />
              <small className="ms-3 text-danger">
                {errorShow.content_link ? errorShow?.content_link[0] : ""}
              </small>
            </div>
            <div className="position-relative justify-content-center d-flex">
              <div
                className="btn whiteButton selectAttachment mx-auto"
                onClick={() => {
                  selectAttachment();
                  setErrorShow("");
                }}
              >
                {fieldPreview ? fieldPreview : "Select Screenshot"}
              </div>
            </div>
            <small className="ms-3 text-danger">
              {errorShow.screenshot ? errorShow?.screenshot[0] : ""}
            </small>
            <div className="my-3 text-center subTitleTexts">
              Please ensure that you have actually posted on your influencer
              platform.
            </div>
          </div>
          <div className="mb-5 justify-content-between d-flex px-5">
            <button
              id="close-submit-link-modal"
              onClick={() => {
                setFieldPreview("");
                setContentLink("");
                setErrorShow("");
                setFilesToBeSent("");
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
              className="whiteButton closeChatModal"
            >
              Discard
            </button>
            <button
              // data-bs-dismiss="modal"
              // aria-label="Close"
              className="greenButton submitChatModal d-flex justify-content-center"
              onClick={() => {
                submitLink();
                setErrorShow("");
              }}
            >
              {workLoading ? (
                <PuffLoader
                  className="d-flex justify-content-center"
                  color="white"
                  size={22}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReviewModal = ({
  placeholder,
  setWorkAccept,
  maxWordCount,
  handleChange,
  description,
  setDescription,
  remainingWords,
  rating,
  handleRatingChange,
  handleAddReview,
  reviewError,
  workLoading,
}) => {
  return (
    <div
      id="review-modal"
      className="modal fade"
      tabIndex="-1"
      aria-labelledby="review-modal"
      aria-hidden="true"
    >
      <div className="modal-dialog user-settings-modal edit-campaign">
        <div className="modal-content">
          <div className="modal-body">
            <img
              className="blue-ball-popup"
              src="/assets/images/blue_ball.png"
              alt="blue-ball"
              width={25}
            />
            <img
              className="popup-yellow-circle"
              src="assets/images/yellow_circle.png"
              alt="circle_3"
              width={16}
            />
            <img
              className="purple-empty-circle-popup"
              src="assets/images/purple_empty_circle.png"
              alt="empty_circle"
              width={20}
            />
            <img
              className="purple-plus-popup"
              src="assets/images/purple_plus.png"
              alt="plus"
              width={16}
            />
            <img
              className="purple-empty-circle-2-popup position-absolute"
              src="assets/images/purple_empty_circle.png"
              alt="empty_circle_2"
              width={16}
            />
            <div
              onClick={() => {
                setDescription("");
                handleRatingChange("");
              }}
              className=" close-btn-box new-lg-close-position"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="review-modal-close"
            >
              <img src="/assets/images/close-Icon2.svg" alt="X" />
            </div>
            <p className="d-flex justify-content-center fs-5 mt-2">
              Leave a Review
            </p>
            <div className="d-flex justify-content-center star-rating mb-4">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className="star {star <= rating ? 'filled' : ''}"
                  onClick={() => handleRatingChange(star)}
                >
                  {star <= rating ? (
                    <img
                      src="/assets/images/Star-filled.png"
                      alt="star"
                      className="mx-1"
                    />
                  ) : (
                    <img
                      src="/assets/images/Star-blank.png"
                      alt="star"
                      className="mx-1"
                    />
                  )}
                </span>
              ))}
            </div>
            <div>
              <textarea
                maxLength={maxWordCount}
                placeholder={placeholder}
                className="review-textarea"
                name="description"
                value={description}
                onChange={handleChange}
              />
              <div
                className={`word-count ${
                  reviewError
                    ? "review-count-error-position"
                    : "review-count-position"
                }`}
              >
                <p> {remainingWords}/250 Characters</p>
              </div>
            </div>
            <div className="ps-3">
              {reviewError ? (
                <span className="errorText">
                  The rating must be atleast 1 start.
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer mt-3">
              <div className="row justify-content-between">
                <div className="col-5">
                  <div
                    onClick={() => {
                      setDescription("");
                      handleRatingChange("");
                    }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="common-button-style-outline align-center fs-6 px-0"
                  >
                    Skip
                  </div>
                </div>
                <div className="col-5">
                  <div
                    onClick={() => {
                      handleAddReview();
                    }}
                    className="common-button-style-filled fs-6 px-0 align-center"
                  >
                    {workLoading ? (
                      <PuffLoader
                        className="justify-content-center"
                        color="white"
                        size={22}
                      />
                    ) : (
                      "Add Review"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AppealInitiated = ({ isSender, userImage, time }) => {
  return (
    <div
      className={`d-flex mt-1 align-items-start  recive-msg ${
        isSender ? "rply-rec-msg" : "rply-send-msg"
      }`}
    >
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <div className="initiated-appeal-msg">
          <span>Initiated an appeal to us!</span>
        </div>
        <div className="txt-time mt-2">{time}</div>
      </div>
    </div>
  );
};

const SubmittedAppeal = ({ imageSource, time }) => {
  return (
    <div className="d-flex align-items-start adjustment-requested-msg recive-msg mt-2">
      <img className="profile-image-sm" src={imageSource} alt="Avtar1" />
      <div className="text-rec-mdg">
        <p>
          <span className="fw-bold">Submitted an Appeal to us!</span>
        </p>
        <div className="txt-time">{time}</div>
      </div>
    </div>
  );
};
// NOT USED ANYWHERE
const SubmissionAccepted = ({ imageSource, time }) => {
  return (
    <div className="d-flex align-items-start submission-accepted-msg recive-msg mt-2">
      <img className="profile-image-sm" src={imageSource} alt="Avtar1" />
      <div className="text-rec-mdg">
        <p>
          <span className="fw-bold">Submission Accepted</span>
        </p>
        <div className="txt-time">{time}</div>
      </div>
    </div>
  );
};

const ProofRejected = (props) => {
  return (
    <div className="d-flex align-items-start submission-rejected-msg recive-msg m-3">
      <img className="profile-image-sm" src={props.userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <p>
          <div className="fw-bold">Proof of submission Rejected</div>
          <div className="text-gray">{props.message}</div>
        </p>
        <div className="txt-time">{props.time}</div>
      </div>
    </div>
  );
};

const WorkAcceptedCollabFinished = () => {
  return (
    <div className="buttons-display">
      <button className="lightGreenButton w-100">
        Work Accepted - Collabration Finished
      </button>
    </div>
  );
};

const AdjustmentRequested = (props) => {
  return (
    <div className="d-flex align-items-start custom-offer-msg recive-msg">
      <img className="profile-image-sm" src={props.userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <p>
          <div className="custom-offer-heading">Adjustment Requested...</div>
          <div className="text-gray">{props.message}</div>
        </p>
        <div className="txt-time">{props.time}</div>
      </div>
    </div>
  );
};

const SentCustomOffer = ({ time, userImage, offer }) => {
  return (
    <div className="d-flex align-items-start custom-offer-msg recive-msg rply-rec-msg">
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <div className="p-message">
          <div className="custom-offer-heading">You Sent a Custom Offer...</div>
          <div className="text-gray">{offer.description}</div>
          <div className="d-flex justify-content-between mt-4">
            <div>
              <span className="custom-offer-heading">Price: </span>
              <span className="text-gray">
                {`$${offer.price
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </span>
            </div>
            <div>
              <span className="custom-offer-heading">Delivery Date: </span>
              <span className="text-gray">
                {offer.delivery_time.split(" ")[0]}
              </span>
            </div>
          </div>
        </div>
        <div className="txt-time">{time}</div>
      </div>
    </div>
  );
};

const ShowOffer = ({ time, userImage, offer, isReceiver }) => {
  return (
    <div
      className={`d-flex align-items-start recive-msg mt-4 ${
        !isReceiver ? "review-sent-msg" : "review-received-msg"
      }`}
    >
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <p>
          <span className="custom-offer-heading">
            You {isReceiver ? "Received" : "Sent"} an Offer...
            <br />
          </span>
          <span className="text-gray">{offer.description}</span>
          <br />
          <span className="d-flex justify-content-between mt-4">
            <span>
              <span className="custom-offer-heading">Price: </span>
              <span className="text-gray">
                {`$${offer?.price
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </span>
              <span className="custom-offer-heading"> per </span>
              <span className="text-gray">{offer.object} </span>
            </span>
            <span>
              <span className="custom-offer-heading">Delivery Date: </span>
              <span className="text-gray">
                {offer.delivery_time.split(" ")[0]}
              </span>
            </span>
          </span>
        </p>
        <div className="txt-time">{time}</div>
      </div>
    </div>
  );
};

const ReceivedCustomOffer = ({ time, userImage, offer }) => {
  return (
    <div className="d-flex align-items-start custom-offer-msg recive-msg">
      <img className="profile-image-sm" src={userImage} alt="Avtar1" />
      <div className="text-rec-mdg">
        <div className="p-message">
          <div className="custom-offer-heading">Received a Custom Offer...</div>
          <div className="text-gray">{offer.description}</div>
          <div className="d-flex justify-content-between mt-4">
            <div>
              <span className="custom-offer-heading">Price: </span>
              <span className="text-gray">
                {`$${offer.price
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </span>
            </div>
            <div>
              <span className="custom-offer-heading">Delivery Date: </span>
              <span className="text-gray">
                {offer.delivery_time.split(" ")[0]}
              </span>
            </div>
          </div>
        </div>
        <div className="txt-time">{time}</div>
      </div>
    </div>
  );
};

const BrandFavoured = () => {
  return (
    <div className="mt-3">
      <p className="decision-notice">Decision was made in favor of the BRAND</p>
      <p className="refunded-notice">Payment is refunded to the BRAND</p>
    </div>
  );
};

const WorkSubmitted = (props) => {
  const goToProofLink = (content_link) => {
    window.open(content_link, "_blank");
  };

  return (
    <>
      <div
        className={`d-flex align-items-start recive-msg m-2 ${
          props.role === "2" ? "work-submit-msg-received" : "work-submit-msg"
        }`}
      >
        <img
          className="profile-image-sm"
          src={props.userImage ?? "/assets/images/404_circle.png"}
          alt="Avatar_1"
        />
        <div className="text-rec-mdg">
          <p>
            <span className="submit-heading">
              {props.linkSubmitted
                ? // || linkAccepted || linkRejected
                  "Link Submitted"
                : "Work Submitted..."}
            </span>
            <br />
            <span className="text-decoration-underline text-primary">
              {props.linkSubmitted ? (
                // || linkAccepted || linkRejected
                <>
                  <span>
                    <a
                      href={props?.screenShot}
                      target="_blank"
                      rel="noreferrer"
                    >
                      screenshot
                    </a>
                  </span>
                  <br />
                  <span className="pointer-cursor">
                    <a onClick={() => goToProofLink(props?.contentLink)}>
                      {props?.contentLink}
                    </a>
                  </span>
                </>
              ) : (
                <a
                  href={
                    props?.link.find((l) => l.id == props.fileSubmission_id)
                      ?.path
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  File Link
                </a>
              )}
            </span>
          </p>
          <div className="txt-time">{props.time}</div>
        </div>
      </div>
    </>
  );
};

function ExtensionMessageReceived(props) {
  return (
    <>
      <div className="d-flex align-items-start recive-msg">
        <img className="profile-image-sm" src={props.userImage} alt="Avtar1" />
        <div className="text-rec-mdg">
          <div className="p-message">
            <div className="extensionReqHeading">
              Requested an Extension of...
            </div>
            <div className="extensionReqTime">{`${props.hours} Hours`}</div>
            <div className="txt-time">{props.time}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function ExtensionMessageSent(props) {
  return (
    <>
      <div className="d-flex align-items-start recive-msg rply-rec-msg">
        <img className="profile-image-sm" src={props.userImage} alt="Avtar1" />
        <div className="text-rec-mdg">
          <div className="p-sent-message">
            <div className="extensionReqHeading">
              Requested an Extension of...
            </div>
            <div className="extensionReqTime">{`${props.hours} Hours`}</div>
            <div className="txt-time">{props.time}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function ParseSystemMessage(props) {
  return <></>;
}

export {
  WorkAcceptModal,
  OtherUserMessage,
  ReviewGiven,
  SentMessage,
  InfluencerLinkModal,
  ReviewModal,
  AppealInitiated,
  SubmittedAppeal,
  SubmissionAccepted,
  ProofRejected,
  WorkAcceptedCollabFinished,
  AdjustmentRequested,
  SentCustomOffer,
  ShowOffer,
  ReceivedCustomOffer,
  BrandFavoured,
  WorkSubmitted,
  ExtensionMessageSent,
  ExtensionMessageReceived,
  ParseSystemMessage,
};
