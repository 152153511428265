import React, { useEffect, useState } from "react";
import AppLayout from "../../../Components/AppLayout";
import { useLocation, useNavigate } from "react-router-dom";
import "./Packs.css";
import { UpdatePack } from "../../../Common/Repository";
import { useFilePicker } from "use-file-picker";
import { toast } from "react-toastify";
import { CloudUploadIcon, InfoIcon } from "../../../Components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import {
  removeAnImage,
  setEditPackImages,
} from "../../../states/packs/packSlice";
import LightTooltip from "../../../Components/LightToolTip";
import { IconButton } from "@mui/material";

// var files = [];

const EditPack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useLocation();
  const [files, setFiles] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [title, setTitle] = useState(data?.state?.title ?? "");
  const [description, setDescription] = useState(
    data?.state?.description ?? ""
  );
  const [maxLength, setMaxLength] = useState(4);
  const [price, setPrice] = useState(data?.state?.price ?? "");
  const [perObject, setperObject] = useState(data?.state?.object ?? "");
  const [deliveryTime, setdeliveryTime] = useState(
    data?.state?.time_duration ?? ""
  );

  useEffect(() => {
    // console.log("previousFiles: ", previousFiles);
  }, [previousFiles]);

  useEffect(() => {
    // console.log("files: ", files);
  }, [files]);

  const { token } = useSelector((state) => state?.auth);

  const deleteImages = [];

  const packId = data?.state?.id;

  const [errors, setErrors] = useState([]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const {
    editLoading,
    editPackSuccess,
    editPackImages,
    editPackDeletedImages,
  } = useSelector((state) => state?.packs);

  useEffect(() => {
    const messageEle = document.getElementById("description");

    const counterEle = document.getElementById("descriptionCounter");

    messageEle.addEventListener("input", function (e) {
      const target = e.target;

      // Count the current number of characters
      const currentLength = target.value.length;

      counterEle.innerHTML = `${currentLength}`;
    });

    const tilteElement = document.getElementById("title");

    const titleCounterElement = document.getElementById("titleCounter");

    tilteElement.addEventListener("input", function (e) {
      const target = e.target;

      // Count the current number of characters
      const currentLength = target.value.length;

      titleCounterElement.innerHTML = `${currentLength}`;
    });
  });

  const [openFileSelector, { filesContent, loading, fileErrors }] =
    useFilePicker({
      readAs: "DataURL",
      accept: "image/jpeg, image/png",
      multiple: true,
      limitFilesConfig: { max: 4 },
      maxFileSize: 2, // in megabytes
      minFileSize: 0.02, // in megabytes
      onFilesRejected: ({errors}) => {
        toast.error(
          errors[0].fileSizeTooSmall
            ? "file size too small"
            : errors[0].fileSizeToolarge
            ? "file size too large. Max file size 2MB"
            : ""
        );
      },
    });

  useEffect(() => {
    if (data?.state?.pack_images !== null) {
      dispatch(setEditPackImages(data?.state?.pack_images));
      setMaxLength(4 - data?.state?.pack_images?.length);
    }
  }, []);

  useEffect(() => {
    if (filesContent !== undefined && filesContent.length > 0) {
      const remainingSlots = 4 - files.length;
      const filesToAdd = filesContent.slice(0, remainingSlots);

      for (let i = 0; i < filesToAdd.length; i++) {
        const foundFile = files.some(
          (file) => file.name === filesToAdd[i].name
        );
        if (!foundFile) {
          setFiles((prevFiles) => [...prevFiles, filesToAdd[i]]);
        }
      }
      // Notify user if attempting to select more than 4 images
      if (filesContent.length > 4) {
        toast.error("You cannot select more than 4 images!");
      }
    }
  }, [filesContent]);

  const handleEditPack = async () => {
    try {
      var formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("object", perObject);
      formData.append("time_duration", deliveryTime);

      // console.log("files:aaaa ", files);

      for (let i = 0; i < files.length; i++) {
        let asd = dataURItoBlob(files[i].content);
        // console.log("asd: ", asd);
        formData.append(`images[${i}]`, asd);
      }

      for (let i = 0; i < editPackDeletedImages.length; i++) {
        formData.append(`deleted_images[${i}]`, editPackDeletedImages[i]);
      }

      formData.append("pack_proposal_id", packId);
      // console.log("formData: ", formData);

      let header = {
        Authorization: `Bearer ${token}`,
      };

      dispatch(UpdatePack({ header, formData }));
    } catch (error) {
      console.log("error?.response?.data: ", error);

      toast.error("Failed to update package!");
      setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (editPackSuccess) {
      setFiles([]);
      navigate(-1);
    }
  }, [editLoading, editPackSuccess]);

  useEffect(() => {
    setPreviousFiles(editPackImages);
    setMaxLength(4 - editPackImages.length);
  }, [editPackImages]);

  function deleteImage(image, index) {
    dispatch(removeAnImage({ id: image.id }));
    setFiles((state) => {
      return state.filter((f, i) => i != index);
    });
  }

  const objectData = ["Story", "Reel", "Short Video", "Graphic Post"];

  const deliveryTimeData = ["1", "2", "3", "4", "5", "6"];

  useEffect(() => {
    // console.log("files: ", files.length);
    // console.log("editPackImages: ", editPackImages.length);
    // let both = files.concat(editPackImages);
    // console.log("editPackImages: both ", files.concat(editPackImages).length);
  }, [files, editPackImages]);

  return (
    <AppLayout>
      <div className="container container-animation">
        <div className="packHeading">Edit Pack</div>
        <div className="packContainerBackground">
          {/* Main Row */}
          <div className="row mobile-col">
            {/* First Column */}
            <div className="columnOne wm-100">
              <p className="inputTitle">Title</p>
              <div className="packsRow">
                <input
                  id="title"
                  type="text"
                  className="leftColumn"
                  value={title}
                  maxLength="120"
                  placeholder="Title..."
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
                <div className="rightColumn">
                  <LightTooltip
                    className="float-right"
                    placement="right"
                    title="Title should be what you are providing."
                  >
                    <IconButton>
                      <InfoIcon width="18" height="18" />
                    </IconButton>
                  </LightTooltip>
                </div>
              </div>
              <div className="counterText">
                <span id="titleCounter">0</span>/120 Characters
              </div>

              <div className="descriptionTextBox">
                <p className="inputTitle">Description</p>
                <div className="packsRow">
                  <textarea
                    id="description"
                    type="text"
                    className="leftColumn"
                    value={description}
                    maxLength={500}
                    placeholder="Description"
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                  />
                  <div className="rightColumn">
                    <LightTooltip
                      className="float-right"
                      placement="right"
                      title="Description should be a short glimpse of the service you
                      are providing. Make sure everything you mention is clear
                      and to the point."
                    >
                      <IconButton>
                        <InfoIcon width="18" height="18" />
                      </IconButton>
                    </LightTooltip>
                  </div>
                </div>
                <div className="counterText">
                  <span id="descriptionCounter">0</span>/500 Characters
                </div>
              </div>
              <div className="fieldsRow">
                <div className="leftColumn position-relative mobile-col phone-align-to-center">
                  <input
                    type="tel"
                    className="width-30 wm-100"
                    value={price}
                    placeholder="Price"
                    maxLength={10}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      // Use a regular expression to remove non-numeric characters
                      const numericValue = inputValue.replace(/[^0-9]/g, "");
                      setPrice(numericValue);
                    }}
                  />
                  <img
                    className="create-pack-dollar-icon hide-on-phone"
                    src="/assets/images/priceDollar.svg"
                    alt="$"
                  />
                  <p className="perText wm-100">Per</p>
                  <select
                    className="width-30 dropDown wm-100 mb-4 mb-md-0"
                    value={perObject}
                    onChange={(event) => {
                      setperObject(event.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select Object
                    </option>
                    {objectData.map((object, index) => (
                      <option key={index} value={object}>
                        {object}
                      </option>
                    ))}
                  </select>
                  <select
                    className="width-30 dropDown wm-100"
                    value={deliveryTime}
                    onChange={(event) => {
                      setdeliveryTime(event.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Delivery Time
                    </option>
                    {deliveryTimeData.map((object, index) => (
                      <option key={index} value={object}>
                        {object} {object === "1" ? "Day" : "Days"}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="rightColumn">
                  <div className="rightColumn">
                    <LightTooltip
                      className="float-right"
                      placement="right"
                      title="Pricing &amp; Duration should be per Pack."
                    >
                      <IconButton>
                        <InfoIcon width="18" height="18" />
                      </IconButton>
                    </LightTooltip>
                    {/* <p>Pricing &amp; Duration should be per Pack.</p> */}
                  </div>
                </div>
              </div>
            </div>
            <span className="verticalDivider hide-on-phone"></span>
            <div className="columnTwo wm-100">
              <div className="shortRow">
                {previousFiles?.length > 0
                  ? previousFiles?.map((image, index) => (
                      <div
                        key={`web-image-${index}`}
                        className="imageContainer"
                        style={{
                          background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85)), url(${image.image})`,
                        }}
                        onClick={() => deleteImage(image, index)}
                      >
                        <div className="deleteImage">Delete</div>
                      </div>
                    ))
                  : ""}

                {files?.length > 0
                  ? files?.map((image, index) => (
                      <div
                        key={`web-image-${index}`}
                        className="imageContainer"
                        style={{
                          background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85)), url(${image.content})`,
                        }}
                        onClick={() => deleteImage(image, index)}
                      >
                        <div className="deleteImage">Delete</div>
                      </div>
                    ))
                  : ""}

                {files.concat(editPackImages).length < 4 ? (
                  <>
                    <div
                      className="imageContainer"
                      onClick={() => openFileSelector()}
                    >
                      <img
                        className="insideImage"
                        alt="gallery"
                        src="/assets/images/packs/uim-image-v.svg"
                      />
                      <div className="insideContainer">
                        <CloudUploadIcon />
                        <span>Upload Photo</span>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <p className="imagesText">
                Upload up to 4 images for your pack.
                <br />
                1st image will be used as your packs default image.
              </p>
            </div>
          </div>
          <div className="buttonsRow wm-100">
            <div
              className="cancelButton wm-100"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </div>
            <div className="saveButton wm-100" onClick={() => handleEditPack()}>
              {editLoading ? (
                <PuffLoader
                  className="justify-content-center d-flex"
                  color="white"
                  size={22}
                />
              ) : (
                <div>Save</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default EditPack;
